<template>
  <div id="zero_success">
    <img class="icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210512141636462.png" alt="">
    <div class="title">兑换成功</div>
    <div class="order_no">订单编号：{{ orderNum }}</div>
    <div class="btns">
      <div @click="goExchange" class="btn btn_">继续兑换</div>
      <div @click="goOrder" class="btn">查看订单</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "success",
  data() {
    return {
      orderID: '',
      orderNum: ''
    }
  },
  created() {
    let {id} = this.$route.params;
    let {orderNum} = this.$route.query
    this.orderID = id;
    this.orderNum = orderNum;
  },
  methods: {
    goOrder() {
      this.$router.push("/Recharge/order_detail?orderId=" + this.orderID + "&orderType=" + 10)
    },
    goExchange() {
      this.$router.push('/zero/exchange')
    }
  }
}
</script>

<style lang="scss">
#zero_success {
  overflow: hidden;

  .btns {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: space-around;
    padding-left: 30px;
    padding-right: 30px;

    .btn {
      padding: 10px 40px;
      border-radius: 500px;
      background-color: rgb(255, 152, 160);
      color: #fff;
      box-sizing: border-box;
    }

    .btn_ {
      border: 1px solid #999;
      background-color: #fff;
      color: #999;
    }
  }

  .icon {
    width: 65px;
    margin-top: 50px;
    position: relative;
    left: 3px;
  }

  .title {
    font-size: 18px;
    margin-top: 15px;
    color: #999;
    font-weight: 600;
    position: relative;
  }

  .order_no {
    margin-top: 6px;
    color: #999;
  }
}
</style>